(function( $ ) {
	'use strict'; 
	 
		jQuery( "body" ).delegate( ".rs_top-trees-hide", "click", function() { 
			jQuery(".rs_top-trees-hide").fadeOut("slow");
		});
		 
		jQuery( "body" ).delegate( ".rs_bottom-trees-hide", "click", function() {
			jQuery(".rs_bottom-trees-hide").fadeOut("slow");
		});
	 

})( jQuery ); 
